import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

class Category extends Component {

    settings = this.props.Settings;

    state = {
        categories: [],
        load: false,
        back: false
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e.target.value);
        console.log(e.target.value);
        console.log(this.props.Language);
    }

    headCover = {'backgroundImage':`url('/assets/cover.jpg')`, 'backgroundSize':'cover'}
    categoryCover = {'backgroundSize': 'cover', 'backgroundPosition': 'center' , 'filter':"brightness(0.9)"}

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head" style={this.headCover}>
                            <Link to={"/"}>
                                {/* onClick={this.back} */}
                                <i  className="backward fa-solid fa-arrow-left"></i>
                            </Link>
                            
                            <select name="language"
                                value={this.props.Language}
                                onChange={this.handleChange}  >
                                <option value="krd">کوردی</option>
                                <option value="ar">عربی</option>
                                <option value="en">English</option>
                            </select>                          
                        <img src={this.settings.logo} alt="" />
                    </div>
                    <div className="info">

                        <div className='address'>
                            <div>
                                <i className="fa-solid fa-map-location-dot"></i><span>{this.settings.address}</span>
                            </div>
                            <div className='phone'>
                                <i className="fa-solid fa-phone"></i> <span>{this.settings.phone}</span> 
                            </div>
                        </div>

                        <div className="social">

                            { 
                                this.settings.facebook
                                &&
                                <a href={this.settings.facebook}><i className="fa-brands fa-facebook"></i></a>
                            }
                            
                            { 
                                this.settings.instgram 
                                &&
                                <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                            }

                            { 
                                this.settings.snapchat
                                &&
                                <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                            }

                            { 
                                this.settings.titok
                                &&
                                <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                            }
                            
                        </div>
                    </div>
                    

                    

                    <div className="category-list">


                        <div className="category-item" >
                            <i className="fas fa-star" style={{'top':'10px', 'rotate':'90deg'}}></i>
                            <Link to={"/menu/restaurant"}>
                                <div className='text'>
                                    <h2> 
                                        {
                                            this.props.Language == "en" ? 'Restutant' : this.props.Language == "krd" ? 'خوارنگەهـ' : 'مطعم'
                                        }
                                    </h2>
                                </div>
                                <div style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Restaurant.jpg'})`, ...this.categoryCover, 'borderRadius': '0px 15px 15px 0px'} }></div>
                            </Link>
                            <i className="fas fa-star" style={{'bottom':'20%', 'left':'95%', 'rotate':'32deg'}}></i>
                        </div>

                        <div className="category-item" >
                            <i className="fas fa-star" style={{'top':'-8px', 'left':'-15px', 'rotate':'45deg'}} ></i>
                            <Link to={"/menu/coffee"}>
                                <div style={ { backgroundImage :`url(${process.env.PUBLIC_URL + 'assets/Coffe.jpg'})`, ...this.categoryCover, 'borderRadius': '15px 0px 0px 15px'} }></div>
                                <div className='text'>
                                    <h2> 
                                        {
                                            this.props.Language == "en" ? 'Coffe shop' : this.props.Language == "krd" ? 'کافی' : 'کافی'
                                        }
                                    </h2>
                                </div>
                            </Link>
                            <i className="fas fa-star" style={{'bottom':'-15px', 'left':'45%', 'rotate':'50deg'}} ></i>
                            <i className="fas fa-star" style={{'top':'40%', 'right':'-16px', 'rotate':'90deg'}} ></i>
                        </div>

                    </div>

                </div>
            </>
        );
    }
}

export default Category;